import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";
import { Injectable, inject } from "@angular/core";

import { environment } from "environments/environment";
import { Observable } from "rxjs";

import { ProctorInfoService } from "./proctor-info.service";

@Injectable()
export class SuperadminInterceptor implements HttpInterceptor {
  private proctorInfoService = inject(ProctorInfoService);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // Skip API
    const isApi =
      req.url.startsWith(environment.APIv1Endpoint) ||
      req.url.startsWith(environment.APIv2Endpoint);
    if (!isApi) {
      return next.handle(req);
    }

    if (
      !this.proctorInfoService.isProctor ||
      !this.proctorInfoService.proctorModeActive()
    ) {
      return next.handle(req);
    }

    const company = this.proctorInfoService.currentProctorCompany;

    const newReq = req.clone({
      headers: req.headers.set(
        "Proctor-Company-Override",
        company.id.toString(),
      ),
    });

    return next.handle(newReq);
  }
}
