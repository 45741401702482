import { Injectable, inject } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";

import { AccountAccessInfo } from "@core/api-models/users.models";
import { UsersService } from "@core/api/users.service";
import { AccessService } from "@core/services/access.service";

import { Observable, catchError, of, switchMap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class OnboardingGuardService {
  private router = inject(Router);
  private accessService = inject(AccessService);
  private usersService = inject(UsersService);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    const onboardNeeded = (account: AccountAccessInfo | null) => {
      if (!(account == null)) {
        return true;
      }

      const shouldAccountOnboard = !account.onboarding_done;

      // If no onboarding is needed, people should go away
      if (![shouldAccountOnboard].some((b) => b)) {
        this.router.navigate(["/"]);
        return false;
      }
      return true;
    };

    if (!this.accessService.current?.account) {
      return this.usersService.getUserAccess().pipe(
        switchMap((access) => {
          this.accessService.updateAvailable(access, false);
          return of(onboardNeeded(this.accessService.current.account));
        }),
        catchError((err) => {
          return of(onboardNeeded(null));
        }),
      );
    }

    return of(onboardNeeded(this.accessService.current.account));
  }
}
