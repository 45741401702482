<router-outlet />

<!--
  The confect-modal-outlet component should be used only here(at the very root) and once,
  since all instances of confect-modal components will be inserted in it.
-->
<confect-modal-outlet />
<confect-context-menu-outlet />
<confect-tooltip-outlet />
<confect-dialog-box-outlet />
<confect-pop-up-editor-outlet />
<confect-update-outlet />
<!-- <confect-help-guide-outlet /> -->
<component-outlet />
