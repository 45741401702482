import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { Observable } from "rxjs";

@Injectable()
export class SharingInterceptor implements HttpInterceptor {
  private route = inject(ActivatedRoute);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!this.route.snapshot.queryParamMap.has("code")) {
      return next.handle(req);
    }

    const code = this.route.snapshot.queryParamMap.get("code");

    const codeReq = req.clone({
      headers: req.headers.set("Confect-Share-Code", code),
    });

    return next.handle(codeReq);
  }
}
